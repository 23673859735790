import AbstractResource from "./abstract-resource"

class AbstractListResource extends AbstractResource {
	constructor(resourceType, resourceItemClass, data) {
		super(resourceType, data)
		this.resourceItemClass = resourceItemClass
		this.items = null
		this.singleItems = {}
		this.count = null
		this.sort = null
		this.loadingSingleItemIds = []
		this.ids = {}
	}

	setItems(items) {
		this.items = []
		items.forEach(item => {
			const model = new this.resourceItemClass(item, this)
			this.ids[model.id] = model
			this.items.push(model)
		})
		this.count = this.items.length
	}

	setSort(sort) {
		this.sort = sort
	}

	getResourceItemClass() {
		return this.resourceItemClass
	}

	hasNotDefaultItems() {
		if (!this.isInitialized()) {
			return false
		}

		for (let i = 0; i < this.items.length; i++) {
			if (!this.items[i].default) {
				return true
			}
		}
		return false
	}


	getPage({limit, offset, searchQuery, sortDirection, sortColumn, searchColumns}) {
		if (!this.isInitialized()) {
			return []
		}

		/*const filters = []
		if (searchColumns && searchQuery) {
			searchColumns.forEach(column => {
				filters.push({
					type: type.FILTER_TYPE_LIKE,
					value: searchQuery,
					field: column
				})
			})
		}*/

		const filteredItems = this.getItems()

		const result = []
		for (let i = offset; i < offset + limit; i++) {
			if (i < filteredItems.length && i >= 0) {
				result.push(filteredItems[i])
			}
		}

		return {
			items: result,
			total: this.count
		}
	}

	getDefaultId() {
		if (!this.isInitialized()) {
			return null
		}

		const items = this.getItems()
		for (let i = 0; i < items.length; i++) {
			if (items[i].default === true) {
				return items[i].id
			}
		}
		return null
	}

	getLength() {
		if (!this.isInitialized()) {
			return null
		}

		return this.count
	}
	
	indexById(id) {
		if (!this.items) {
			return -1
		}

		for (let i = 0; i < this.items.length; i++) {
			if (id === this.items[i].id) {
				return i
			}
		} 
		return -1
	}

	findById(id) {
		if (!this.isInitialized()) {
			return null
		}

		if (this.ids[id]) {
			return this.ids[id]
		}

		for (let i = 0; i < this.items.length; i++) {
			if (id === this.items[i].id) {
				return this.items[i]
			}
		}
		return null
	}

	getFieldStr(item, field) {
		if (!item[field]) {
			return ''
		}

		if (typeof item[field] === 'object') {
			return Object.values(item[field]).join('')
		}

		return item[field]
	}

	filter(params, mode = 'and') {
		if (!this.items) {
			return []
		}

		if (!params || !params.length) {
			return this.items
		}

		const result = []
		const items = this.getItems()
		items.forEach(item => {
			let match = null
			params.every((param) => {
				if (item[param.field] !== undefined && param.value !== undefined) {
					if (mode === 'and') {
						if (match === null) {
							match = true
						}
						if (param.type === type.FILTER_TYPE_EQUAL &&
							param.value !== item[param.field]) {
							match = false
							return false
						} else if (param.type === type.FILTER_TYPE_LIKE) {
							const fieldValue = this.getFieldStr(item, param.field)
							if ((fieldValue && fieldValue.toLowerCase().indexOf(param.value.toLowerCase()) < 0) || !fieldValue) {
								match = false
								return false
							}
						} else if (param.type === type.FILTER_TYPE_IN_ARRAY) {
							if (item[param.field] && param.value.indexOf(item[param.field]) < 0) {
								match = false
								return false
							}
						}
					} else if (mode === 'or') {
						if (match === null) {
							match = false
						}

						if (param.type === type.FILTER_TYPE_EQUAL &&
							param.value === item[param.field]) {
							match = true
							return false
						} else if (param.type === type.FILTER_TYPE_LIKE) {
							const fieldValue = this.getFieldStr(item, param.field)
							if ((fieldValue && fieldValue.toLowerCase().indexOf(param.value.toLowerCase()) >= 0) || !fieldValue) {
								match = true
								return false
							}
						} else if (param.type === type.FILTER_TYPE_IN_ARRAY) {
							if (item[param.field] && param.value.indexOf(item[param.field]) >= 0) {
								match = true
								return false
							}
						}
					}
				}
				return true
			})
			if (match === true) {
				result.push(item)
			}
		})

		return result
	}

	upload(params) {
		return (dispatch) => {
			dispatch({type: `${this.resourceType}_CREATING`})
			this.apiCallbacks.upload(params).then((data) => {
				dispatch(this.created(data))
			}).catch((err) => {
				this.handleErrorCodes(err)
				dispatch({type: `${this.resourceType}_CREATE_FAILED`, error: err})
			})
		}
	}


	create(params) {
		return (dispatch) => {
			dispatch({type: `${this.resourceType}_CREATING`})
			this.apiCallbacks.create(params).then((data) => {
				dispatch(this.created(data))
			}).catch((err) => {
				this.handleErrorCodes(err)
				dispatch({type: `${this.resourceType}_CREATE_FAILED`, error: err})
			})
		}
	}

	created(data, remoteUpdate = false) {
		return (dispatch) => {

			if (this.findById(data.id)) {
				dispatch(this.updated(data, remoteUpdate))
				return
			}

			if (!this.items) {
				this.items = []
				this.count = 0
			}

			const model = new this.resourceItemClass(data, this)
			this.ids[model.id] = model
			this.items.push(model)
			this.count++
			dispatch(this.onCreated(model))
			if (remoteUpdate) {
				dispatch({type: `${this.resourceType}_REMOTE_CREATING`})
				dispatch({type: `${this.resourceType}_REMOTE_CREATED`, id: data.id, item: model})
			} else {
				dispatch({type: `${this.resourceType}_CREATED`, id: data.id, item: model})
			}
		}
	}

	clear() {
		this.clearData()
		return (dispatch) => {
			dispatch({type: `${this.resourceType}_CLEAR`})
		}
	}

	clearSingleItem(id) {
		this.clearSingleData(id)
		return (dispatch) => {
			dispatch({type: `${this.resourceType}_SINGLE_ITEM_CLEAR`, id})
		}
	}

	clearSingleData(id) {
		delete this.singleItems[id]
	}

	clearSingleItems() {
		this.singleItems = {}
		return (dispatch) => {
			dispatch({type: `${this.resourceType}_SINGLE_ITEM_CLEAR`})
		}
	}

	clearData() {
		this.ids = {}
		this.items = null
		this.count = null
	}

	delete(id) {
		return (dispatch) => {
			dispatch({type: `${this.resourceType}_DELETING`, id})
			this.apiCallbacks.delete(id).then(() => {
				dispatch(this.deleted(id))
			}).catch((err) => {
				console.log(err)
				this.handleErrorCodes(err)
				dispatch({type: `${this.resourceType}_DELETE_FAILED`, id, error: err})
			})
		}
	}

	deleted(id, remoteUpdate = false) {
		return (dispatch) => {
			if (!this.isInitialized()) {
				return
			}

			let ids = [id]
			if (Array.isArray(id)) {
				ids = id
			}

			const item = []
			ids.forEach(id => {
				const index = this.indexById(id)
				if (index >= 0) {
					delete this.ids[this.items[index].id]
					item.push(this.items[index])
					this.items.splice(index, 1)
					this.count--
				}
			})
			if (item.length) {
				dispatch(this.onDeleted(item))
				if (remoteUpdate) {
					dispatch({type: `${this.resourceType}_REMOTE_DELETING`, id})
					dispatch({type: `${this.resourceType}_REMOTE_DELETED`, id})
				} else {
					dispatch({type: `${this.resourceType}_DELETED`, id})
				}
			}
		}
	}

	update(id, params) {
		let ids = [id]
		if (Array.isArray(id)) {
			ids = id
		}

		return (dispatch) => {
			const restoreItems = []
			ids.forEach(idItem => {
				if (idItem === null) {
					restoreItems.push({data: this.mergeData(params), item: this})
				} else {
					const item = this.findById(idItem)
					if (item) {
						restoreItems.push({data: item.mergeData(params), item})
					}
				}
				if (this.singleItems[idItem]) {
					this.singleItems[idItem].mergeData(params)
				}
			})

			dispatch({type: `${this.resourceType}_UPDATING`, id})
			this.apiCallbacks.update(id, params).then((data) => {
				dispatch(this.updated(data))
			}).catch((err) => {
				restoreItems.forEach(restoreItem => {
					restoreItem.item.mergeData(restoreItem.data)
					if (this.singleItems[restoreItem.id]) {
						this.singleItems[restoreItem.id].mergeData(restoreItem.data)
					}
				})

				this.handleErrorCodes(err)
				dispatch({type: `${this.resourceType}_UPDATE_FAILED`, id, error: err})
			})
		}
	}

	updated(data, remoteUpdate = false) {
		return (dispatch) => {
			const items = []
			let id = []
			if (Array.isArray(data)) {
				data.forEach(dataItem => {
					const index = this.indexById(dataItem.id)
					if (index >= 0) {
						id.push(dataItem.id)
						this.items[index].mergeData(dataItem)
						items.push(this.items[index])
					}

					if (this.singleItems[dataItem.id]) {
						this.singleItems[dataItem.id].mergeData(dataItem)
					}
				})
			} else {
				const index = this.indexById(data.id)
				if (index >= 0) {
					id = data.id
					this.items[index].mergeData(data)
					items.push(this.items[index])
				}

				if (this.singleItems[data.id]) {
					this.singleItems[data.id].mergeData(data)
				}

				if (data.id === this.id) {
					this.mergeData(data)
				}
			}

			if (items.length > 0 || this.singleItems[data.id] || data.id === this.id) {
				dispatch(this.onUpdated(data))
				if (remoteUpdate) {
					dispatch({type: `${this.resourceType}_REMOTE_UPDATING`, id})
					dispatch({type: `${this.resourceType}_REMOTE_UPDATED`, id})
				} else {
					dispatch({type: `${this.resourceType}_UPDATED`, id})
				}
			}
		}
	}

	refresh(params = {}) {
		return this.load(params, true)
	}

	refreshSingleItem(id, params = {}, storeId = null) {
		return this.loadSingleItem(id, params, true, storeId)
	}

	getSingleItem(id) {
		return this.singleItems[id]
	}

	getSingleItems() {
		return this.singleItems
	}

	isSingleItemInitialized(id) {
		return !!this.singleItems[id]
	}

	onSingleItemLoaded(item, id) {
		return (dispatch) => {
			const children = item.getChildren()
			if (children) {
				dispatch(children.load())
			}
		}
	}

	loadSingleItem(id, params = {}, ignoreCache = false, storeId = null) {
		return (dispatch) => {
			if (!storeId) {
				storeId = id
			}

			if (this.loadingSingleItemIds.indexOf(storeId) >= 0) {
				return
			}

			this.loadingSingleItemIds.push(storeId)
			dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVING`})
			if (this.singleItems[storeId] && ignoreCache === false) {
				dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVED`})

				const index = this.loadingSingleItemIds.indexOf(storeId)
				if (index >= 0) {
					this.loadingSingleItemIds.splice(index, 1)
				}
			} else {

				delete this.singleItems[storeId]
				this.apiCallbacks.read(id, params).then((data) => {
					this.singleItems[storeId] = new this.resourceItemClass(data, this)
					dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVED`, id: storeId})
					dispatch(this.onSingleItemLoaded(this.singleItems[storeId], storeId))
				}).catch((err) => {
					this.handleErrorCodes(err)
					dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVE_FAILED`, error: err})
				}).finally(() => {
					const index = this.loadingSingleItemIds.indexOf(storeId)
					if (index >= 0) {
						this.loadingSingleItemIds.splice(index, 1)
					}
				})
			}
		}
	}

	load(params = {}, ignoreCache = false) {
		return (dispatch) => {
			if (this.isLoading) {
				return
			}

			dispatch({type: `${this.resourceType}_RECEIVING`})
			if (!this.canView() && this.apiCallbacks.dropDownList === undefined) {
				this.items = []
				this.ids = {}
				this.count = 0
				dispatch({type: `${this.resourceType}_RECEIVED`})
				dispatch(this.onLoaded(this.items))
				return
			}

			this.isLoading = true
			if (this.isInitialized() && ignoreCache === false) {
				dispatch({type: `${this.resourceType}_RECEIVED`})
				this.isLoading = false
			} else {
				const functionCall = this.canView() ? this.apiCallbacks.get(params) : this.apiCallbacks.dropDownList(params)
				functionCall.then((data) => {
					this.items = []
					this.ids = {}
					this.count = 0
					Object.keys(data).forEach(key => {
						if (key === 'rows') {
							data.rows.forEach(item => {
								try {
									const model = new this.resourceItemClass(item, this)
									this.ids[model.id] = model
									this.items.push(model)
								} catch (e) {
									console.log(e)
								}
							})
						} else {
							this[key] = data[key]
						}
					})

					dispatch({type: `${this.resourceType}_RECEIVED`})
					dispatch(this.onLoaded(this.items))
				}).catch((err) => {
					this.handleErrorCodes(err)
					dispatch({type: `${this.resourceType}_RECEIVE_FAILED`, error: err})
				}).finally(() => {
					this.isLoading = false
				})
			}
		}
	}

	isInitialized() {
		return (this.items !== null)
	}

	getItems() {
		if (!this.isInitialized()) {
			return []
		}

		if (this.sort) {
			return this.items.sort(this.sort)
		}

		return this.items
	}

	getItemsAsObject() {
		const result = {}
		this.getItems().forEach(item => {
			result[item.id] = item
		})
		return result
	}

	getItem(index) {
		if (index < 0 || index >= this.items.length) {
			return null
		}

		return this.items[index]
	}
}

export default AbstractListResource