import AbstractItemResource from "../abstract/abstract-item-resource"
import resourceCollection from "../abstract/resource-collection"
import defines from "dpsdk/defines"
const {LogAction, Resource} = defines

class Log extends AbstractItemResource {
    constructor(data, parent) {
        super(Resource.LOGS, data, parent)
    }

    getUser() {
        return resourceCollection.getResource(Resource.USERS).findById(this.userId)
    }

    getDetails(t) {
        return t(`logs.details.${this.type}`, {ip: this.ip, refId: this.ref_id, userId: this.user_id, restarts: this.restarts})
    }
}

export default Log