import AbstractListResource from "./abstract/abstract-list-resource"
import defines from "dpsdk/defines"
const {Resource, DeviceVerificationFilter, DeviceWorkStatusFilter, DeviceEnabledType} = defines
import Verification from './items/verification'

class Devices extends AbstractListResource {
    constructor() {
        super(Resource.VERIFICATIONS, Verification)
        super.setCallbacks({
            get: (params) => this.api().resourceGet(Resource.VERIFICATIONS, params),
            update: (id, params) => this.api().resourceUpdate(Resource.VERIFICATIONS, id, params),
            create: (params) => this.api().resourceCreate(Resource.VERIFICATIONS, params),
            delete: (id) => this.api().resourceDelete(Resource.VERIFICATIONS, id)
        })
    }

    violationsForDropDown(t, empty) {
        const result = []
        if (empty) {
            result.push({
                label: empty,
                value: null
            })
        }

        return result
    }

    enabledForDropDown(t) {
        return [
            {
                label: t('devices.asDefinedInDetectionProfile'),
                value: DeviceEnabledType.PROFILE
            },
            {
                label: t('devices.disabled'),
                value: DeviceEnabledType.DISABLED
            },
            {
                label: t('devices.enabled'),
                value: DeviceEnabledType.ENABLED
            },
            {
                label: t('devices.maintenance'),
                value: DeviceEnabledType.MAINTENANCE
            }
        ]
    }

    verificationsForDropDown(t, empty) {
        const result = []
        if (empty) {
            result.push({
                label: empty,
                value: null
            })
        }
        result.push(
            {
                label: t('devices.verificationNone'),
                value: DeviceVerificationFilter.NONE
            },
            {
                label: t('devices.verificationHas'),
                value: DeviceVerificationFilter.HAS
            }
        )

        return result
    }

    checkDurationForDropDown(t) {
        const result = []
        for (let i = 1; i <= 5; i++) {
            result.push({
                value: i,
                label: `${i * 12} ${t('devices.months')}`
            })
        }
        return result
    }


    workingStatusForDropDown(t, empty) {
        const result = []
        if (empty) {
            result.push({
                label: empty,
                value: null
            })
        }

        result.push(
            {
                label: t('devices.notWorking'),
                value: DeviceWorkStatusFilter.NOT_WORKING
            },
            {
                label: t('devices.working'),
                value: DeviceWorkStatusFilter.WORKING
            },
            {
                label: t('devices.errors'),
                value: DeviceWorkStatusFilter.ERRORS
            },
            {
                label: t('devices.noReportsMoreThan1hour'),
                value: DeviceWorkStatusFilter.NO_REPORTS_1HOUR_OR_MORE
            },
            {
                label: t('devices.noReportsMoreThan24hours'),
                value: DeviceWorkStatusFilter.NO_REPORTS_24HOUR_OR_MORE
            }
        )

        return result
    }

    export() {
        return this.api().resourceExport(Resource.DEVICES)
    }

    markers() {
        return this.api().resourceGet(`${Resource.DEVICES.toLowerCase()}/map/markers`)
    }

    forDropDown(empty) {
        if (!this.isInitialized()) {
            return []
        }

        const result = []
        if (empty) {
            result.push({
                label: empty,
                value: null
            })
        }
        this.getItems().forEach(item => {
            result.push({
                label: `#${item.id} ${item.title}`,
                value: item.id
            })
        })

        return result
    }
}

export default new Devices()