import AbstractListResource from "./abstract/abstract-list-resource"
import defines from "dpsdk/defines"
const {Resource} = defines
import Statistic from './items/statistic'

class Statistics extends AbstractListResource {
	constructor() {
		super(Resource.STATISTICS, Statistic)
		super.setCallbacks({
			read: (id, params) => this.api().resourceRead(Resource.STATISTICS, id, params)
		})
	}

}

export default new Statistics()