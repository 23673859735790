import * as status from '../api/statuses'
import resourceCollection from "./resource-collection"
import defines from 'dpsdk/defines'
import ability from '../../configs/acl/ability'

const { Resource } = defines

class AbstractResource {
	constructor(resourceType, data = null, workspaceId = undefined) {
		this.resourceType = resourceType
		this.propsToUpdate = {}
		this.loadingPromise = null
		this.dataKeys = []
		this.labels = {}
		if (workspaceId !== undefined) {
			this.workspaceId = workspaceId
		} else {
			this.workspaceId = localStorage.getItem('workspace')
		}

		this.setData(data)
	}

	static defineForDropDown(define, labelPrefix, t) {
		const result = []
		Object.keys(define).forEach(key => {
			result.push({
				value: define[key],
				label: t(`${labelPrefix}.${define[key]}`)
			})
		})
		return result
	}

	api() {
		return resourceCollection.getApi()
	}

	resetError() {
		return (dispatch) => {
			dispatch({type: `${this.resourceType}_UNKNOWN`, error: null})
		}
	}

	isSuperAdminResource() {
		return false
	}

	getDnD() {
		return null
	}

	getParentField() {
		return null
	}

	getParent() {
		return null
	}

	getResourceType() {
		return this.resourceType
	}

	getConnectedResourceTypes() {
		return []
	}

	getPossibleActions() {
		if (!this.apiCallbacks) {
			return []
		}

		const result = []
		if (this.apiCallbacks.get !== undefined || this.apiCallbacks.read !== undefined) {
			result.push('view')
		}
		if (this.apiCallbacks.update !== undefined) {
			result.push('edit')
		}
		if (this.apiCallbacks.create !== undefined) {
			result.push('create')
		}
		if (this.apiCallbacks.delete !== undefined) {
			result.push('delete')
		}
		return result
	}

	setLabels(labels) {
		this.labels = labels
	}

	getLabel(key, t) {
		if (this.labels[key] === undefined) {
			return null
		}
		return t(this.labels[key])
	}

	details(t, overwrite = {}) {
		const result = {}
		Object.keys(this.labels).forEach(key => {
			if (this[key] !== undefined) {
				let value = this[key]
				if (overwrite[key] !== undefined) {
					value = overwrite[key]
				}
				if (value === true) {
					value = t('Yes')
				} else if (value === false) {
					value = t('No')
				}

				result[key] = {
					label: this.getLabel(key, t),
					value
				}
			}
		})
		return result
	}

	canView() {
		return ability.can('view', this.resourceType)
	}

	canEdit() {
		return ability.can('edit', this.resourceType)
	}

	canCreate() {
		return ability.can('create', this.resourceType)
	}

	canDelete() {
		return ability.can('delete', this.resourceType)
	}

	onCreated(item) {
		return (dispatch) => {
			dispatch(resourceCollection.getResource(Resource.LOGS).clear())
		}
	}

	onUpdated(item) {
		return (dispatch) => {
			dispatch(resourceCollection.getResource(Resource.LOGS).clear())
		}
	}

	onDeleted(item) {
		return (dispatch) => {
			dispatch(resourceCollection.getResource(Resource.LOGS).clear())
		}
	}

	onLoaded(item) {
		return (dispatch) => {
		}
	}

	setCallbacks(callback) {
		this.apiCallbacks = callback
	}

	setWorkspace(workspaceId) {
		return (dispatch) => {
			this.workspaceId = workspaceId
			dispatch(this.clear())
		}
	}

	getWorkspaceId() {
		const workspaceResources = Object.keys(resourceCollection.getWorkspaceResources())
		if (workspaceResources.indexOf(this.resourceType) < 0) {
			return undefined
		}

		return resourceCollection.getResource(Resource.PROFILE).getCurrentWorkspaceId(this.workspaceId)
	}

	getData() {
		if (!this.isInitialized()) {
			return null
		}

		const result = {}
		this.dataKeys.forEach(key => {
			result[key] = this[key]
		})
		return result
	}

	setData(data) {
		const previousValues = this.clearData()
		if (data) {
			Object.keys(data).forEach(key => {
				if (this.dataKeys.indexOf(key) < 0) {
					this.dataKeys.push(key)
				}

				this[key] = data[key]
			})
		}
		return previousValues
	}

	clear() {
		return (dispatch) => {
			this.clearData()
			dispatch({type: `${this.resourceType}_CLEAR`})
		}
	}

	clearData() {
		const previousValues = {}
		this.dataKeys.forEach(key => {
			previousValues[key] = this[key]
			this[key] = undefined
		})
		this.dataKeys = []
		return previousValues
	}


	mergeData(data) {
		const previousValues = {}
		Object.keys(data).forEach(key => {
			if (this.dataKeys.indexOf(key) < 0) {
				this.dataKeys.push(key)
			}
			previousValues[key] = this[key]
			this[key] = data[key]
		})
		return previousValues
	}

	isInitialized() {
		return (this.dataKeys.length > 0)
	}

	requestForRefresh() {
		return (dispatch) => {
			dispatch({type: `${this.resourceType}_REQUEST_FOR_REFRESH`})
		}
	}

	handleErrorCodes(error) {
		if (error) {
			switch (error.status) {
				case status.SERVER_ERROR:
					//window.location.href = '/500'
					break
				case status.NOT_AUTHORIZED:
					//window.location.href = '/login'
					break
				case status.NOT_FOUND:
					//window.location.href = '/404'
					break
			}
		}
	}

	update(params) {
		return (dispatch) => {
			const previousData = this.mergeData(params)
			dispatch({type: `${this.resourceType}_UPDATING`})
			this.apiCallbacks.update(params).then((data) => {
				dispatch(this.updated(data))
			}).catch((err) => {
				this.mergeData(previousData)
				this.handleErrorCodes(err)
				dispatch({type: `${this.resourceType}_UPDATE_FAILED`, error: err})
			})
		}
	}

	updated(data, forceUpdate = false) {
		return (dispatch) => {
			this.setData(data)
			dispatch(this.onUpdated(this))
			if (forceUpdate) {
				dispatch({type: `${this.resourceType}_UPDATING`})
			}
			dispatch({type: `${this.resourceType}_UPDATED`})
		}
	}

	refresh(params = {}) {
		return this.load(params, true)
	}

	load(params, ignoreCache = false, onPromise = undefined) {
		return (dispatch) => {
			if (this.loadingPromise) {
				if (onPromise !== undefined) {
					onPromise(this.loadingPromise)
				}
				return
			}

			dispatch({type: `${this.resourceType}_RECEIVING`})
			if (this.isInitialized() && ignoreCache === false) {
				dispatch({type: `${this.resourceType}_RECEIVED`})
			} else {
				this.loadingPromise = this.apiCallbacks.get(params).then((data) => {
					this.setData(data)
					dispatch(this.onLoaded(this))
					dispatch({type: `${this.resourceType}_RECEIVED`})
				}).catch((err) => {
					this.handleErrorCodes(err)
					dispatch({type: `${this.resourceType}_RECEIVE_FAILED`, error: err})
				}).finally(() => {
					this.loadingPromise = null
				})
				if (onPromise !== undefined) {
					onPromise(this.loadingPromise)
				}
			}
		}
	}
}

export default AbstractResource