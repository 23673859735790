const productionDomain = `${window.location.protocol}//${window.location.host}`
const developmentDomain = `${window.location.protocol}//${process.env.API_HOST ? process.env.API_HOST : 'localhost'}`
const apiHOST = process.env.NODE_ENV === "development" ? developmentDomain : productionDomain

const apiPORT = process.env.NODE_ENV === "development" ? process.env.API_PORT ? process.env.API_PORT : 9079 : ''
const apiURL = `${apiHOST}${apiPORT ? `:${apiPORT}` : ``}/api/dashboard`
const hostURL = `${apiHOST}${apiPORT ? `:${apiPORT}` : ``}`
const socketURL = `${apiHOST}:${process.env.SOCKET_PORT ? process.env.SOCKET_PORT : '81'}`
const socialLoginRedirectUrl = process.env.NODE_ENV === "development" ? developmentDomain : productionDomain
const config = {
    apiURL,
    socketURL,
    hostURL,
    redirectUrl: socialLoginRedirectUrl
}

export default config

window.config = config