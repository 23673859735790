import AbstractListResource from "./abstract/abstract-list-resource"
import defines from "dpsdk/defines"
const {Resource} = defines
import User from './items/user'

class Users extends AbstractListResource {
	constructor() {
		super(Resource.USERS, User)
		super.setCallbacks({
			read: (id, params) => this.api().resourceRead(Resource.USERS, id, params),
			get: (params) => this.api().resourceGet(Resource.USERS, params),
			update: (id, params) => this.api().resourceUpdate(Resource.USERS, id, params),
			delete: (id) => this.api().resourceDelete(Resource.USERS, id),
			create: (params) => this.api().resourceCreate(Resource.USERS, params)
		})
	}

	getConnectedResourceTypes() {
		return [Resource.ROLES]
	}

	forDropDown(empty = undefined) {

		const result = []
		if (!this.isInitialized()) {
			return []
		}

		if (empty) {
			result.push(empty)
		}

		this.getItems().forEach(item => {
			result.push({
				value: item.id,
				label: item.getFullName()
			})
		})
		return result
	}
}

export default new Users()