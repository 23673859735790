import AbstractItemResource from "../abstract/abstract-item-resource"
import defines from "dpsdk/defines"
import Zone from './zone'
import resourceCollection from "../abstract/resource-collection"
import {formatDate, formatTime, humanDuration} from "../../utility/Utils"
const {Resource, DeviceEnabledType, DeviceDoorStatus} = defines

class Verification extends AbstractItemResource {
    constructor(data, parent) {
        super(Resource.VERIFICATIONS, data, parent)
        this.setLabels({
            id: 'ID'
        })

        this.zoneInstances = []
        if (Array.isArray(this.zones)) {
            this.zones.forEach((zone) => {
                this.zoneInstances.push(new Zone(zone))
            })
        }
    }

    getFullTitle() {
        return `${this.title} [#${this.id}]`
    }

    getLocation() {
        return this.location
    }


    getNumberOfSources() {
        const sources = this.video_size.split(',')
        return sources.length
    }

    getZones() {
        return this.zoneInstances
    }

    isOnline() {
        if (this.server_time - this.ping_at <= 60) {
            return true
        }
        return false
    }

    checkTill() {
        if (!this.checked_at) {
            return 0
        }

        return this.checked_at + (this.check_duration * 365 * 24 * 3600) - (3600 * 24)
    }

    getVerificationStatusStr(t) {
        const checkTill = this.checkTill()
        const status = []
        if (this.checked_at) {
            status.push(<div>{formatDate(this.checked_at * 1000)}</div>)
        }

        if (checkTill === 0) {
            status.push(<div className={'text-danger'}>{t('devices.noVerification')}</div>)
        } else if (checkTill < this.server_time) {
            status.push(<div className={'text-danger'}>{t('devices.validationExpired')}</div>)
        } else {
            if (checkTill < this.server_time + (3600 * 24 * 30 * 4)) {
                status.push(<div className={'text-warning'}>{t('devices.validationOk')}</div>)
            } else {
                status.push(<div className={'text-success'}>{t('devices.validationOk')}</div>)
            }
        }
        return status
    }

    getStatusStr(t) {
        const status = []

        if (this.isOnline()) {
            if (this.queue > 0) {
                status.push(<div className='text-success'>{t('devices.statusReportsDelivery', {queue: this.queue})}</div>)
            } else {
                switch (this.getEnabled()) {
                    case DeviceEnabledType.ENABLED:
                        status.push(<div className="text-success">{t('devices.available')}</div>)
                        break
                    case DeviceEnabledType.MAINTENANCE:
                        status.push(<div className="text-warning">{t('devices.maintenance')}</div>)
                        break
                    case DeviceEnabledType.DISABLED:
                        status.push(<div className="text-info">{t('devices.disabled')}</div>)
                        break
                    default:
                        status.push(null)
                        break
                }
            }

            if (this.alerts) {
                status.push(<div className="text-warning">{t('devices.errors')}</div>)
            }

            if (this.temperature !== null && this.temperature !== undefined) {
                status.push(<div className="text-info">{t('devices.temp', {temperature: this.temperature})}</div>)
            }

            if (this.door_status === DeviceDoorStatus.CLOSED) {
                status.push(<div className="text-muted">{t('devices.doorOk')}</div>)
            } else if (this.door_status === DeviceDoorStatus.OPEN) {
                status.push(<div className="text-muted">{t('devices.doorOpened')}</div>)
            }
        } else {
            status.push(<div className="text-danger">{t('devices.unavailable')}</div>)
        }

        if (this.isOnline()) {
            if (this.last_report_at > 0 && this.server_time - this.last_report_at > 3600) {
                status.push(<div className="text-danger">{t('devices.lastReportAt', {time: formatTime(new Date(this.last_report_at * 1000)), date: formatDate(new Date(this.last_report_at * 1000))})}</div>)
            }
        } else if (this.last_report_at === 0) {
            status.push(<div className="text-danger">{t('devices.noReports')}</div>)
        }

        if (this.isOnline()) {
            if (this.started_at) {
                status.push(<div className='text-muted' style={{fontSize: "10px"}}>{t('devices.uninterrupted', {duration: humanDuration(this.server_time - this.started_at)})}</div>)
            }
        } else {
            if (this.disconnected_at) {
                status.push(<div className="text-muted" style={{fontSize: "10px"}}>{t('devices.disabledSince', {duration: humanDuration(this.server_time - this.disconnected_at)})}</div>)
            }
        }
        return status
    }

    getDetectionProfile() {
        if (!this.detection_profile_id) {
            return null
        }

        return resourceCollection.getResource(Resource.DETECTION_PROFILES).findById(this.detection_profile_id)
    }

    getEnabled() {
        let enabled = this.enabled
        const detectionProfile = this.getDetectionProfile()
        if (this.enabled === DeviceEnabledType.PROFILE && detectionProfile) {
            enabled = detectionProfile.enabled
        }
        return enabled
    }


    getViolationCodes() {
        const result = []
        this.getZones().forEach(zone => {
            if (zone.codeStr()) {
                result.push(zone.codeStr())
            }
        })
        return result.join(', ')
    }
}

export default Verification