import AbstractListResource from "./abstract/abstract-list-resource"
import Log from './items/log'
import defines from "dpsdk/defines"
const {LogAction, Resource} = defines

class Logs extends AbstractListResource {
    constructor() {
        super(Resource.LOGS, Log)
        super.setCallbacks({
            get: (params) => this.api().resourceGet(Resource.LOGS, params)
        })
    }

    getConnectedResourceTypes() {
        return [Resource.USERS]
    }
}

export default new Logs()