import AbstractItemResource from "../abstract/abstract-item-resource"
import defines from "dpsdk/defines"
const {Resource} = defines


class Statistic extends AbstractItemResource {
	constructor(data, parent) {
		super(Resource.STATISTICS, data, parent)
	}

}

export default Statistic