import AbstractListResource from "./abstract/abstract-list-resource"
import defines from "dpsdk/defines"
const {Resource, VehicleColor, TrashRepStatus} = defines
import VehicleLog from './items/vehiclelog'
import countryList from "country-list"
import iso from "iso-3166-1"
import countries from "i18n-iso-countries"

class VehicleLogs extends AbstractListResource {
    constructor() {
        super(Resource.VEHICLE_LOGS, VehicleLog)

        countries.registerLocale(require("i18n-iso-countries/langs/en.json"))
        countries.registerLocale(require("i18n-iso-countries/langs/ru.json"))

        super.setCallbacks({
            read: (id, params) => this.api().resourceRead(Resource.VEHICLE_LOGS, id, params),
            get: (params) => this.api().resourceGet(Resource.VEHICLE_LOGS, params)
        })
    }

    colorsForDropDown(t, empty) {
        const result = []

        if (empty) {
            result.push({
                label: empty,
                value: null
            })
        }

        Object.values(VehicleColor).forEach(color => {
            if (parseInt(color) >= 0) {
                result.push({
                    label: t(`vehicleLogs.color.${color}`),
                    value: color
                })
            }
        })
        return result
    }

    trashRepStatusForDropDown(t, empty) {
        const result = []

        if (empty) {
            result.push({
                label: empty,
                value: null
            })
        }

        Object.values(TrashRepStatus).forEach(color => {
            if (parseInt(color) >= 0) {
                result.push({
                    label: t(`vehicleLogs.trashRepStatus.${color}`),
                    value: color
                })
            }
        })
        return result
    }

    countryCodesForDropDown(language, empty) {
        const data = []

        if (empty) {
            data.push({
                label: empty,
                value: null
            })
        }

        countryList.getData().forEach(item => {

            const isoItem = iso.whereAlpha2(item.code)
            if (isoItem) {
                data.push({
                    label: countries.getName(isoItem.alpha2, language) || isoItem.country,
                    value: isoItem.numeric
                })
            }
        })
        return data
    }

    classSizesForDropDown(t, empty) {
        const result = []

        if (empty) {
            result.push({
                label: empty,
                value: null
            })
        }

        ['A', 'B', 'C', 'D', 'E', 'F', 'J', 'M', 'S'].forEach((letter) => {
            result.push({
                label: letter,
                value: letter
            })
        })

        result.push({
            label: t('vehicleLogs.unknown'),
            value: 'U'
        })

        return result
    }
    cargoTypesForDropDown(t, empty) {
        const result = []
        if (empty) {
            result.push({
                label: empty,
                value: null
            })
        }
        result.push({
            label: t('vehicleLogs.cargo'),
            value: 1
        })
        result.push({
            label: t('vehicleLogs.empty'),
            value: 2
        })
        result.push({
            label: t('vehicleLogs.trash'),
            value: 3
        })
        return result
    }
    typesForDropDown(t, empty) {
        const result = []
        if (empty) {
            result.push({
                label: empty,
                value: null
            })
        }
        result.push({
            label: t('vehicleLogs.cars'),
            value: 1
        })
        result.push({
            label: t('vehicleLogs.trucks'),
            value: 2
        })
        result.push({
            label: t('vehicleLogs.buses'),
            value: 3
        })
        return result
    }

}

export default new VehicleLogs()
