import AbstractListResource from "./abstract/abstract-list-resource"
import defines from "dpsdk/defines"
const {Resource, DetectionProfileAllowedDirection, DetectionProfileExport, DetectionProfilePlateDetector} = defines
import DetectionProfile from './items/detectionprofile'

class DetectionProfiles extends AbstractListResource {
    constructor() {
        super(Resource.DETECTION_PROFILES, DetectionProfile)
        super.setCallbacks({
            read: (id, params) => this.api().resourceRead(Resource.DETECTION_PROFILES, id, params),
            get: (params) => this.api().resourceGet(Resource.DETECTION_PROFILES, params),
            update: (id, params) => this.api().resourceUpdate(Resource.DETECTION_PROFILES, id, params),
            create: (params) => this.api().resourceCreate(Resource.DETECTION_PROFILES, params),
            delete: (id) => this.api().resourceDelete(Resource.DETECTION_PROFILES, id)
        })
    }

    forDropDown(empty) {
        if (!this.isInitialized()) {
            return []
        }

        const result = []
        if (empty) {
            result.push({
                label: empty,
                value: null
            })
        }
        this.getItems().forEach(item => {
            result.push({
                label: `#${item.id} ${item.title}`,
                value: item.id
            })
        })

        return result
    }

    timeZonesForDropDown() {
        const result = []
        for (let i = -12; i <= 12; i++) {
            result.push({
                label: i > 0 ? `UTC+${i} ` : `UTC${i} `,
                value: i
            })
        }
        return result
    }

    allowedDirectionsForDropDown(t) {
        return [
            {
                label: t('detectionProfiles.allDirections'),
                value: DetectionProfileAllowedDirection.ALL
            },
            {
                label: t('detectionProfiles.oppositeDirectionOnly'),
                value: DetectionProfileAllowedDirection.OPPOSITE
            },
            {
                label: t('detectionProfiles.passingDirectionOnly'),
                value: DetectionProfileAllowedDirection.PASSING
            }
        ]
    }

    exportForDropDown(t) {
        return [
            {
                label: t('detectionProfiles.none'),
                value: DetectionProfileExport.NONE
            },
            {
                label: t('detectionProfiles.angel'),
                value: DetectionProfileExport.ANGEL
            }
        ]
    }

    plateDetectorsForDropDown(t) {
        return [
            {
                label: t('detectionProfiles.default'),
                value: 0
            },
            {
                label: t('detectionProfiles.dpRoadLib'),
                value: 1
            }
        ]
    }

    speedDetectorsForDropDown(t) {
        return [
            {
                label: t('detectionProfiles.disabled'),
                value: 0
            },
            {
                label: t('detectionProfiles.video'),
                value: 1
            },
            {
                label: t('detectionProfiles.radar'),
                value: 2
            },
            {
                label: t('detectionProfiles.video2'),
                value: 3
            }
        ]
    }
}

export default new DetectionProfiles()
