import resourceCollection from "./abstract/resource-collection"
import AbstractResource from "./abstract/abstract-resource"
import defaultAvatar from '@src/assets/images/avatars/default.png'
import { REDUX_STATUS } from "./abstract/abstract-reducer"
import defines from 'dpsdk/defines'
const {Resource} = defines

class Profile extends AbstractResource {
    constructor() {
        super(Resource.PROFILE)
        super.setCallbacks({
            get: () => this.api().currentUser(),
            update: (data) => this.api().updateProfile(data)
        })
    }

    versionsForDropDown(empty) {
        const result = []
        if (empty) {
            result.push({
                label: empty,
                value: ''
            })
        }
        this.versions.forEach((version) => {
            result.push({
                label: version,
                value: version
            })
        })
        return result
    }

    getRoleType() {
        return this.role ? this.role.type : null
    }

    getRoleId() {
        return this.role ? this.role.type : null
    }

    getFullName() {
        if (this.firstName && this.lastName) {
            return `${this.firstName} ${this.lastName}`
        }
        return this.firstName
    }

    getAvatar() {
        if (this.files && this.files.avatar) {
            const date = Date.parse(this.updatedAt)
            return `${this.files.avatar}?t=${date}`
        }
        return defaultAvatar
    }

    deleteAvatar() {
        return (dispatch) => {
            this.api().deleteAvatar().then(() => {
                dispatch(this.refresh())
                dispatch(resourceCollection.getResource(Resource.USERS).clear())
            }).catch((errors) => {

            })
        }
    }

    uploadAvatar(file) {
        return (dispatch) => {
            this.api().uploadAvatar(file).then(() => {
                dispatch(this.refresh())
                dispatch(resourceCollection.getResource(Resource.USERS).clear())
            }).catch((errors) => {

            })
        }
    }

    logout() {
        return (dispatch) => {
            this.api().userLogout()
            const resources = resourceCollection.getResources()
            Object.keys(resources).forEach(resourceType => {
                dispatch(resources[resourceType].clear())
            })
            dispatch({type: 'LOGOUT'})
        }
    }

    propagateServices() {
        return this.api().propagateServices()
    }

    onUpdated(data) {
        return (dispatch) => {
            dispatch(super.onUpdated(data))
            dispatch(resourceCollection.getResource(Resource.USERS).clear())
        }
    }
}

export default new Profile()