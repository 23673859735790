import AbstractItemResource from "../abstract/abstract-item-resource"
import defines from "dpsdk/defines"
const {Resource} = defines

class Communal extends AbstractItemResource {
    constructor(data, parent) {
        super(Resource.COMMUNALS, data, parent)
        this.setLabels({
            id: 'ID'
        })
    }
}

export default Communal