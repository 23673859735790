import AbstractItemResource from "../abstract/abstract-item-resource"
import defines from "dpsdk/defines"
const {RoleType, Resource} = defines
import AbstractResource from "../abstract/abstract-resource"

class Role extends AbstractItemResource {
	constructor(data, parent) {
		super(Resource.ROLES, data, parent)
		this.setLabels({
			id: 'ID',
			title: 'roles.title'
		})
	}

	static typesForDropDown(t) {
		return AbstractResource.defineForDropDown(RoleType, 'roles', t)
	}

	static editableTypesForDropDown(t) {
		return [
			{
				value: RoleType.ADMINISTRATOR,
				label: t('roles.administrator')
			},
			{
				value: RoleType.DEALER,
				label: t('roles.dealer')
			},
			{
				value: RoleType.CONTENT_OWNER,
				label: t('roles.contentOwner')
			}
		]
	}

	getColor() {
		switch (this.type) {
			case RoleType.OPERATOR:
				return 'primary'
			case RoleType.ADMINISTRATOR:
				return 'warning'
			case RoleType.DEALER:
				return 'secondary'
			case RoleType.CONTENT_OWNER:
				return 'info'
			default:
				return 'danger'
		}
	}

	getTitle(t) {
		switch (this.type) {
			case RoleType.OPERATOR:
				return t('roles.operator')
			default:
				return this.title ? this.title : t('roles.unnamedRole')
		}
	}
}

export default Role