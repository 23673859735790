import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './i18n/en.json'
import ru from './i18n/ru.json'

const resources = {
    en: {
        translations: en
    },
    ru: {
        translations: ru
    }
}

const defaultLanguage = localStorage.getItem('locale') === 'en' ? 'en' : 'ru'

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: defaultLanguage,
        fallbackLng: 'ru',
        debug: false,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            wait: false,
            useSuspense: false
        }
    })

export default i18n