import AbstractItemResource from "../abstract/abstract-item-resource"
import defines from "dpsdk/defines"
const {TemplateType, Resource} = defines
import AbstractResource from "../abstract/abstract-resource"
import resourceCollection from '../abstract/resource-collection'

class DetectionProfile extends AbstractItemResource {
    constructor(data, parent) {
        super(Resource.DETECTION_PROFILES, data, parent)
        this.setLabels({
            id: 'ID'
        })
    }

    getTitle(t) {
        return this.title ? this.title : t('templates.unnamedTemplate')
    }

    getTypeStr(t) {
        const item = DetectionProfile.typesForDropDown(t).find(item => item.value === this.type)
        if (item) {
            return item.label
        }
        return null
    }

    static availableMacros() {
        return [
            'FIRST_NAME', 'LAST_NAME', 'CODE',
            'EMAIL', 'PHONE', 'USERNAME',
            'PASSWORD', 'IP', 'OPERATOR_NAME',
            'COUNTRY', 'CITY', 'ADDRESS', 'ZIP_CODE',
            'PLAN_NAME', 'SHOP_ITEM_NAME', 'PRICE',
            'CURRENCY', 'BEGIN', 'END'
        ]
    }

    getDeliveryMethodStr(t) {
        const plugins = resourceCollection.getResource(Resource.PLUGIN_INSTANCES)
        const pluginInstance = plugins.findById(this.pluginInstanceId)
        if (!pluginInstance) {
            return null
        }

        return pluginInstance.title
    }

    static typesForDropDown(t) {
        return AbstractResource.defineForDropDown(TemplateType, 'templates', t)
    }
}

export default DetectionProfile
