import AbstractItemResource from "../abstract/abstract-item-resource"
import resourceCollection from "../abstract/resource-collection"
import defaultAvatar from '@src/assets/images/avatars/default.png'

import languages from 'language-list'
const languageList = languages()

import CurrencyList from 'currency-list'
const countryList = require('country-list')

import defines from "dpsdk/defines"
const {Resource, OS, ApplicationType} = defines

class User extends AbstractItemResource {
	constructor(data, parent) {
		super(Resource.USERS, data, parent)

		this.setLabels({
			id: 'ID',
			firstName: 'users.firstName',
			lastName: 'users.lastName',
			email: 'users.email',
			roleType: 'users.roleType',
			roleId: 'users.role',
			disabled: 'users.disabled'
		})
	}

	static currenciesForDropDown(language) {
		const result = []
		const list = Object.keys(CurrencyList.getAll('en_US'))
		list.forEach((key) => {
			const item = CurrencyList.get(key, language)
			result.push({
				value: key,
				label: `${item.name_plural} (${item.code} / ${item.symbol})`
			})
		})
		return result
	}

	static languagesForDropDown() {
		const list = languageList.getData()
		const data = []
		list.forEach(item => {
			data.push({
				label: item.language,
				value: item.code
			})
		})
		return data
	}

	static countriesForDropDown() {
		const data = []
		countryList.getData().forEach(item => {
			data.push({
				label: item.name,
				value: item.code
			})
		})
		return data
	}

	static osForDropDown(empty, application) {
		const result = []
		if (empty) {
			result.push({
				label: empty,
				value: null
			})
		}

		if (application) {
			switch (application) {
				case ApplicationType.MOBILE:
					return [
						{
							label: 'Mobile Android',
							value: OS.MOBILE_ANDROID
						},
						{
							label: 'iOS',
							value: OS.IOS
						}
					]
				case ApplicationType.WEB:
					return [
						{
							label: 'Browser',
							value: 'browser'
						}
					]
				case ApplicationType.TV:
					return [
						{
							label: 'TV Android',
							value: OS.TV_ANDROID
						},
						{
							label: 'Tizen',
							value: OS.TIZEN
						},
						{
							label: 'WebOS',
							value: OS.WEBOS
						}
					]
			}
		}

		return [
			{
				label: 'TV Android',
				value: OS.TV_ANDROID
			},
            {
                label: 'Mobile Android',
                value: OS.MOBILE_ANDROID
            },
			{
				label: 'iOS',
				value: OS.IOS
			},
			{
				label: 'Tizen',
				value: OS.TIZEN
			},
			{
				label: 'WebOS',
				value: OS.WEBOS
			},
			{
				label: 'Browser',
				value: 'browser'
			}
		]
	}

	canDelete() {
		const profile = resourceCollection.getResource(Resource.PROFILE)
		if (profile.id === this.id) {
			return false
		}

		return super.canDelete()
	}

	canEdit() {
		const profile = resourceCollection.getResource(Resource.PROFILE)
		//if (profile.id === this.id) {
		//	return false
		//}

		return super.canEdit()
	}

	static permissionsForDialog(t) {
		return [
			{title: t('permission.vehicles'), view: 'viewVehicleLog'},
			{title: t('permission.detectionProfiles'), view: 'viewDetectionProfile', edit: 'updateDetectionProfile', delete: 'deleteDetectionProfile', create: 'createDetectionProfile'},
			{title: t('permission.devices'), view: 'viewDevice', edit: 'updateDevice', delete: 'deleteDevice', create: 'createDevice'},
			{title: t('permission.verification'), view: 'viewVerification', edit: 'updateVerification'},
			{title: t('permission.zones'), view: 'viewZone', edit: 'updateZone', delete: 'deleteZone', create: 'createZone'},
			{title: t('permission.communalVehicles'), view: 'viewCommunal', delete: 'deleteCommunal', create: 'createCommunal'},
			{title: t('permission.alerts'), view: 'viewAlert', edit: 'updateAlert', delete: 'deleteAlert', create: 'createAlert'},
			{title: t('permission.accessLogs'), view: 'viewLog'},
			{title: t('permission.neuroNet'), view: 'viewNeuroNet', edit: 'updateNeuroNet', delete: 'deleteNeuroNet'},
			{title: t('permission.users'), view: 'viewAccount', edit: 'updateAccount', delete: 'deleteAccount', create: 'createAccount'},
			{title: t('permission.deviceVerification'), view: 'viewCheck', edit: 'updateCheck', create: 'createCheck'}
		]
	}

	getFullName() {
		return `${this.username} / ${this.email}`
	}

	sendInvitationLink() {
		return this.api().sendInvitationLink(this.id)
	}

	getAvatar() {
		if (this.files && this.files.avatar) {
			const date = Date.parse(this.updatedAt)
			return `${this.files.avatar}?t=${date}`
		}
		return defaultAvatar
	}

	getStatusColor() {
		if (this.disabled) {
			return 'dark'
		} else if (!this.emailVerified) {
			return 'warning'
		}
		return 'success'
	}

	getStatus(t) {
		if (this.disabled) {
			return t('users.disabled')
		} else if (!this.emailVerified) {
			return t('users.emailVerification')
		}
		return t('users.active')
	}

	getRole() {
		return resourceCollection.getResource(Resource.ROLES).findById(this.roleId)
	}
}

export default User