import AbstractListResource from "./abstract/abstract-list-resource"
import defines from "dpsdk/defines"
const {Resource} = defines
import Communal from './items/communal'

class Communals extends AbstractListResource {
    constructor() {
        super(Resource.COMMUNALS, Communal)
        super.setCallbacks({
            get: (params) => this.api().resourceGet(Resource.COMMUNALS, params),
            create: (params) => this.api().resourceCreate(Resource.COMMUNALS, params),
            delete: (id) => this.api().resourceDelete(Resource.COMMUNALS, id)
        })
    }
}

export default new Communals()