// ** Redux Imports
import { combineReducers } from 'redux'
import api from '../../api'
// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import defines from 'dpsdk/defines'
const { Resource } = defines

import profileResource from "../../resources/profile"
import usersResource from "../../resources/users"
import rolesResource from "../../resources/roles"
import detectionProfilesResource from "../../resources/detectionprofiles"
import logsResource from "../../resources/logs"
import devicesResource from "../../resources/devices"
import statisticsResource from "../../resources/statistics"
import zonesResource from "../../resources/zones"
import communalsResource from "../../resources/communals"
import alertsResource from "../../resources/alerts"
import vehicleLogsResource from "../../resources/vehiclelogs"
import verificationsResource from "../../resources/verifications"
import resourceCollection from '../../resources/abstract/resource-collection'

resourceCollection.setApi(api)

resourceCollection.add(Resource.DETECTION_PROFILES, detectionProfilesResource)

resourceCollection.add(Resource.DEVICES, devicesResource)
resourceCollection.add(Resource.VERIFICATIONS, verificationsResource)
resourceCollection.add(Resource.PROFILE, profileResource)
resourceCollection.add(Resource.USERS, usersResource)
resourceCollection.add(Resource.ROLES, rolesResource)
resourceCollection.add(Resource.LOGS, logsResource)
resourceCollection.add(Resource.STATISTICS, statisticsResource)
resourceCollection.add(Resource.ZONES, zonesResource)
resourceCollection.add(Resource.VEHICLE_LOGS, vehicleLogsResource)
resourceCollection.add(Resource.COMMUNALS, communalsResource)
resourceCollection.add(Resource.ALERTS, alertsResource)

const reducers = resourceCollection.getReducers()
reducers.navbar = navbar
reducers.layout = layout

const rootReducer = combineReducers(reducers)

export default rootReducer
