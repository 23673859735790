import AbstractListResource from "./abstract/abstract-list-resource"
import defines from "dpsdk/defines"
const {Resource, ZoneType, ZoneScheduleDays, Direction} = defines
import Zone from './items/zone'

class Zones extends AbstractListResource {
    constructor() {
        super(Resource.ZONES, Zone)
        super.setCallbacks({
            get: (params) => this.api().resourceGet(Resource.ZONES, params),
            update: (id, params) => this.api().resourceUpdate(Resource.ZONES, id, params),
            create: (params) => this.api().resourceCreate(Resource.ZONES, params),
            delete: (id) => this.api().resourceDelete(Resource.ZONES, id)
        })
    }

    weekDaysDorDropDown(t) {
        const result = []
        result.push(
            {
                label: t('zones.everyDay'),
                value: ZoneScheduleDays.EVERY_DAY
            },
            {
                label: t('zones.monday'),
                value: ZoneScheduleDays.MONDAY
            },
            {
                label: t('zones.tuesday'),
                value: ZoneScheduleDays.TUESDAY
            },
            {
                label: t('zones.wednesday'),
                value: ZoneScheduleDays.WEDNESDAY
            },
            {
                label: t('zones.thursday'),
                value: ZoneScheduleDays.THURSDAY
            },
            {
                label: t('zones.friday'),
                value: ZoneScheduleDays.FRIDAY
            },
            {
                label: t('zones.saturday'),
                value: ZoneScheduleDays.SATURDAY
            },
            {
                label: t('zones.sunday'),
                value: ZoneScheduleDays.SUNDAY
            },
            {
                label: t('zones.workingDays'),
                value: ZoneScheduleDays.WORKING_DAYS
            },
            {
                label: t('zones.dayOffs'),
                value: ZoneScheduleDays.DAY_OFFS
            }
        )
        return result
    }

    hoursForDropDown() {
        const result = []
        for (let i = 0; i < 24; i++) {
            result.push({
                label: i < 10 ? `0${i}` : i,
                value: i
            })
        }
        return result
    }

    minsForDropDown() {
        const result = []
        for (let i = 0; i < 60; i++) {
            result.push({
                label: i < 10 ? `0${i}` : i,
                value: i
            })
        }
        return result
    }

    directionsForDropDown(t) {
        const result = []
        result.push({
            label: t('zones.oncomingDirection'),
            value: Direction.ONCOMING
        })

        result.push({
            label: t('zones.passingDirection'),
            value: Direction.PASSING
        })

        return result
    }

    typesForDropDown(t) {
        const result = []
        Object.values(ZoneType).forEach(type => {
            if (parseInt(type) > 0 && type !== ZoneType.NONE) {
                result.push({
                    label: t(`zones.type.${type}`),
                    value: type
                })
            }
        })
        return result
    }
}

export default new Zones()