import AbstractItemResource from "../abstract/abstract-item-resource"
import defines from "dpsdk/defines"
const {Resource, ZoneType, ZoneShape, Direction} = defines
import AbstractResource from "../abstract/abstract-resource"

class Zone extends AbstractItemResource {
    constructor(data, parent) {
        super(Resource.ZONES, data, parent)
        this.setLabels({
            id: 'ID'
        })
    }

    static typesForDropDown(t) {
        return AbstractResource.defineForDropDown(ZoneType, 'zones', t)
    }

    getShape() {
        if (this.type === ZoneType.ARROW_STOP_LINE_STRAIGHT || this.type === ZoneType.ARROW_STOP_LINE || this.type === ZoneType.STOP_LINE || this.type === ZoneType.DELIMETER || this.type === ZoneType.DOUBLE_DELIMETER) {
            return ZoneShape.LINE
        } else if (this.type === ZoneType.STOP_LIGHT || this.type === ZoneType.ARROW_LIGHT) {
            return ZoneShape.RECT
        }
        return ZoneShape.QUADR
    }

    typeStr(t) {
        return t(`zones.type.${this.type}`)
    }

    directionStr(t) {
        switch (this.direction) {
            case Direction.ONCOMING:
                return t('zones.oncomingDirection')
            case Direction.PASSING:
                return t('zones.passingDirection')
            default:
                return null
        }
    }

    static staticViolationName(type) {
        const code = Zone.staticCodeStr(type)
        const types = {
            [ZoneType.LANE]: 'C1.1',
            [ZoneType.RADAR]: 'C1.1',
            [ZoneType.BUS]: 'C1.1',
            [ZoneType.SIDEWALK]: 'C4',
            [ZoneType.SHOULDER]: 'C3',
            [ZoneType.RAILS]: 'C8',
            [ZoneType.STOP_LINE]: 'C13',
            [ZoneType.ARROW_STOP_LINE]: 'C13',
            [ZoneType.ARROW_STOP_LINE_STRAIGHT]: 'C13',
            [ZoneType.AFTER_STOP_LINE]: 'C11',
            [ZoneType.STOP_LIGHT]: 'C13/C12',
            [ZoneType.ARROW_LIGHT]: 'C13/C12',
            [ZoneType.DELIMETER]: 'C11',
            [ZoneType.DOUBLE_DELIMETER]: 'C11',
            [ZoneType.COMMUNAL_LANE]: 'C6',
            [ZoneType.ONE_WAY]: 'C9',
            [ZoneType.TRUCK]: 'C16',
            [ZoneType.ZONE_ONCOMING_DRIVE]: 'C7',
            [ZoneType.MOVING]: 'C10',
            [ZoneType.INTERVAL_MEASURE]: 'C1.1',
            [ZoneType.INTERVAL_MEASURE_CAR]: 'C1.1',
            [ZoneType.INTERVAL_MEASURE_TRUCK]: 'C1.1',
            [ZoneType.INTERVAL_MEASURE_BUS]: 'C1.1',
            [ZoneType.WAFFLE]: 'C1.26'
        }
        return `${code}: ${types[type]}`
    }

    getColor() {
        switch (this.type) {
            case ZoneType.ARROW_LIGHT:
                return '#22CC22'
            case ZoneType.STOP_LINE:
                return '#CCCC22'
            case ZoneType.ARROW_STOP_LINE:
            case ZoneType.ARROW_STOP_LINE_STRAIGHT:
                return '#22CC22'
            case ZoneType.DELIMETER:
                return '#BBBBBB'
            case ZoneType.DOUBLE_DELIMETER:
                return '#FFFFFF'
            case ZoneType.LANE:
            case ZoneType.RADAR:
                return '#32BAFF'
            case ZoneType.SIDEWALK:
                return '#964218'
            case ZoneType.SHOULDER:
                return '#BBBBBB'
            case ZoneType.RAILS:
                return '#1A4193'
            case ZoneType.COMMUNAL_LANE:
                return '#FFFFFF'
            case ZoneType.ONE_WAY:
                return '#777777'
            case ZoneType.ZONE_ONCOMING_DRIVE:
                return '#777777'
            case ZoneType.TRUCK:
            case ZoneType.TRUCK_STAT:
            case ZoneType.ILLEGAL_PARKING:
                return '#CC22AA'
            case ZoneType.PLACE_TRASH:
                return '#36c20f'
            case ZoneType.LOW_QUALITY_TRASH_REMOVAL:
            case ZoneType.OUTSIDE_CONTAINER_TRASH:
                return '#24d3c0'
            case ZoneType.BUS:
                return '#000000'
            case ZoneType.INTERVAL_MEASURE:
            case ZoneType.INTERVAL_MEASURE_CAR:
            case ZoneType.INTERVAL_MEASURE_TRUCK:
            case ZoneType.INTERVAL_MEASURE_BUS:
                return '#0000FF'
            case ZoneType.INTEREST:
                return '#FFFFFF'
            default:
                return '#CC2222'
        }
    }

    static staticCodeStr(type) {
        const types = {
            [ZoneType.LANE]: 'C1.1',
            [ZoneType.RADAR]: 'C1.1',
            [ZoneType.BUS]: 'C1.1',
            [ZoneType.SIDEWALK]: 'C4',
            [ZoneType.SHOULDER]: 'C3',
            [ZoneType.RAILS]: 'C8',
            [ZoneType.STOP_LINE]: 'C13',
            [ZoneType.ARROW_STOP_LINE]: 'C13',
            [ZoneType.ARROW_STOP_LINE_STRAIGHT]: 'C13',
            [ZoneType.AFTER_STOP_LINE]: 'C12',
            [ZoneType.STOP_LIGHT]: 'C13/C12',
            [ZoneType.ARROW_LIGHT]: 'C13/C12',
            [ZoneType.DELIMETER]: 'C11',
            [ZoneType.DOUBLE_DELIMETER]: 'C11',
            [ZoneType.COMMUNAL_LANE]: 'C6',
            [ZoneType.ONE_WAY]: 'C9',
            [ZoneType.TRUCK]: 'C16',
            [ZoneType.ZONE_ONCOMING_DRIVE]: 'C7',
            [ZoneType.MOVING]: 'C10',
            [ZoneType.INTERVAL_MEASURE]: 'C1.1',
            [ZoneType.INTERVAL_MEASURE_CAR]: 'C1.1',
            [ZoneType.INTERVAL_MEASURE_TRUCK]: 'C1.1',
            [ZoneType.INTERVAL_MEASURE_BUS]: 'C1.1',
            [ZoneType.WAFFLE]: 'C1.26',
            [ZoneType.CROSSWALK_UNCONTROLLED]: '',
            [ZoneType.BELT]: 'C16'
        }

        if (types[type]) {
            return types[type]
        }

        return ''
    }

    static getViolationZoneTypes() {
        return [
            ZoneType.LANE,
            ZoneType.RADAR,
            ZoneType.INTERVAL_MEASURE,
            ZoneType.INTERVAL_MEASURE_CAR,
            ZoneType.INTERVAL_MEASURE_TRUCK,
            ZoneType.INTERVAL_MEASURE_BUS,
            ZoneType.SIDEWALK,
            ZoneType.SHOULDER,
            ZoneType.RAILS,
            ZoneType.ZONE_ONCOMING_DRIVE,
            ZoneType.STOP_LINE,
            ZoneType.ARROW_STOP_LINE,
            ZoneType.ARROW_STOP_LINE_STRAIGHT,
            ZoneType.AFTER_STOP_LINE,
            ZoneType.DELIMETER,
            ZoneType.DOUBLE_DELIMETER,
            ZoneType.COMMUNAL_LANE,
            ZoneType.ONE_WAY,
            ZoneType.TRUCK,
            ZoneType.MOVING,
            ZoneType.WAFFLE,
            ZoneType.CROSSWALK_UNCONTROLLED,
            ZoneType.BELT,
            ZoneType.ILLEGAL_PARKING,
            ZoneType.PLACE_TRASH,
            ZoneType.LOW_QUALITY_TRASH_REMOVAL,
            ZoneType.OUTSIDE_CONTAINER_TRASH
        ]
    }

    static violationsForDropDown(t, empty = '', additional = '', withCode = true) {
        const res = []
        if (empty) {
            res.push({
                label: empty,
                value: null
            })
        }

        if (additional) {
            res.push({
                label: additional,
                value: -1
            })
        }

        Zone.getViolationZoneTypes().forEach(zoneType => {
            res.push({
                label: t(`zones.violation.${zoneType}`),
                value: zoneType
            })
        })

     /*   res.push({
            label: t('Превышение скорости'),
            value: ZoneType.LANE
        })

        res.push({
            label: t('Превышение скорости на участке'),
            value: ZoneType.INTERVAL_MEASURE
        })

        res.push({
            label: t('Превышение скорости на участке (легковые)'),
            value: ZoneType.INTERVAL_MEASURE_CAR
        })

        res.push({
            label: t('Превышение скорости на участке (грузовики)'),
            value: ZoneType.INTERVAL_MEASURE_TRUCK
        })

        res.push({
            label: t('Превышение скорости на участке (автобусы)'),
            value: ZoneType.INTERVAL_MEASURE_BUS
        })

        res.push({
            label: t('Выезд на тротуар'),
            value: ZoneType.SIDEWALK
        })

        res.push({
            label: t('Выезда на обочину'),
            value: ZoneType.SHOULDER
        })

        res.push({
            label: t('Выезд на трамвайные пути'),
            value: ZoneType.RAILS
        })

        res.push({
            label: t('Выезд на встречную полосу движения'),
            value: ZoneType.ZONE_ONCOMING_DRIVE
        })

        res.push({
            label: t('Выезд за стоп линию на ЗСС'),
            value: ZoneType.STOP_LINE
        })

        res.push({
            label: t('Выезд за стоп линию не по стрелке (прямо нельзя)'),
            value: ZoneType.ARROW_STOP_LINE
        })

        res.push({
            label: t('Выезд за стоп линию не по стрелке (прямо можно)'),
            value: ZoneType.ARROW_STOP_LINE_STRAIGHT
        })

        res.push({
            label: t('Проезд перекретска на ЗСС'),
            value: ZoneType.AFTER_STOP_LINE
        })

        res.push({
            label: t('Пересечение сплошной линии'),
            value: ZoneType.DELIMETER
        })

        res.push({
            label: t('Пересечение двойной сплошной линии'),
            value: ZoneType.DOUBLE_DELIMETER
        })

        res.push({
            label: t('Выезд на полосу общественного транспорта'),
            value: ZoneType.COMMUNAL_LANE
        })

        res.push({
            label: t('Встречне движение по дороге с односторонним движением'),
            value: ZoneType.ONE_WAY
        })

        res.push({
            label: t('Движение грузовых автомобилей запрещено'),
            value: ZoneType.TRUCK
        })

        res.push({
            label: t('Перестроение с нарушением ПДД'),
            value: ZoneType.MOVING
        })

        res.push({
            label: t('Выезд на перекресток с затором'),
            value: ZoneType.WAFFLE
        })*/

        if (withCode) {
            return res.map(el => {
                return Zone.staticCodeStr(el.value) ? {label: `${Zone.staticCodeStr(el.value)}: ${el.label}`, value: el.value} : el
            })
        }
        return res
    }

    static violationStr(t, code) {
        const el = Zone.violationsForDropDown(t).find(el => el.value === code)
        if (el) {
            return el.label
        }
        return ''
    }

    codeStr() {
        return Zone.staticCodeStr(this.type)
    }

    saveCoordinates() {
        return this.update({x1: this.x1, x2: this.x2, x3: this.x3, x4: this.x4, y1: this.y1, y2: this.y2, y3: this.y3, y4: this.y4})
    }

    violationCode() {

    }
}

export default Zone
