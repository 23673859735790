import {Ability, AbilityBuilder} from '@casl/ability'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))
import defines from "dpsdk/defines"
const { RoleType, Resource} = defines

const getResourceNameByPermission = (permissionName) => {
    permissionName = permissionName.replace('view', '')
    permissionName = permissionName.replace('delete', '')
    permissionName = permissionName.replace('create', '')
    permissionName = permissionName.replace('update', '')
    switch (permissionName) {
        case 'VehicleLog':
            return Resource.VEHICLE_LOGS
        case 'Device':
            return Resource.DEVICES
        case 'Account':
            return Resource.USERS
        case 'DetectionProfile':
            return Resource.DETECTION_PROFILES
        case 'Zone':
            return Resource.ZONES
        case 'Communal':
            return Resource.COMMUNALS
        case 'Alert':
            return Resource.ALERTS
        case 'Log':
            return Resource.LOGS
    }
}

export const prepareRules = (userData) => {
    const abilityBuilder = new AbilityBuilder(Ability)
    if (!userData) {
        return initialAbility
    }

    if (userData.id === 1) {
        abilityBuilder.can('manage', 'all')
    } else {
        if (Array.isArray(userData.permissions)) {
            userData.permissions.forEach(permission => {
                const permissionName = getResourceNameByPermission(permission.name)
                if (permissionName) {
                    if (permission.name.startsWith('view')) {
                        abilityBuilder.can('view', permissionName)
                    } else if (permission.name.startsWith('update')) {
                        abilityBuilder.can('edit', permissionName)
                    } else if (permission.name.startsWith('create')) {
                        abilityBuilder.can('create', permissionName)
                    } else if (permission.name.startsWith('delete')) {
                        abilityBuilder.can('delete', permissionName)
                    }
                }
            })
        }
        abilityBuilder.can('manage', Resource.PROFILE)
        abilityBuilder.can('view', Resource.STATISTICS)
    }

    return abilityBuilder.rules
}
export default new Ability(prepareRules(userData))
