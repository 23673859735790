import AbstractResource from "./abstract-resource"
import ability from '../../configs/acl/ability'
import User from "../items/user"

class AbstractItemResource extends AbstractResource {
    constructor(resourceType, data, parent) {
        super(resourceType, data)
        this.parent = parent
    }

    hasAvailabilityLimits() {
        return !((!this.beginAt || this.beginAt === this.createdAt) && !this.endAt)
    }

    isAvailable() {
        if (!this.hasAvailabilityLimits()) {
            return true
        }

        const now = new Date()

        return (!this.beginAt || now >= Date.parse(this.beginAt)) && (!this.endAt || now <= Date.parse(this.endAt))
    }

    getChildren() {
        return this.children
    }

    refresh(params, storeId) {
        return (dispatch) => {
            if (!storeId) {
                storeId = this.id
            }

            try {
                dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVING`})
                const previousData = this.getData()
                const id = this.id
                this.clearData()
                this.parent.apiCallbacks.read(id, params).then((data) => {
                    this.setData(data)
                    dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVED`, id: storeId})
                }).catch((err) => {
                    this.setData(previousData)
                    this.handleErrorCodes(err)
                    dispatch({type: `${this.resourceType}_SINGLE_ITEM_RECEIVE_FAILED`, error: err})
                })
            } catch (e) {
                console.log(e)
            }

        }
    }

    osStr(t) {
        if (!this.os || !this.os.length) {
            return t('allPlatforms')
        }

        const result = []
        User.osForDropDown().forEach(el => {
            if (this.os.includes(el.value)) {
                result.push(el.label)
            }
        })
        return result.join(', ')
    }

    delete() {
        if (this.parent) {
            return this.parent.delete(this.id)
        }
    }

    update(params) {
        if (this.parent) {
            return this.parent.update(this.id, params)
        }
    }

    canView() {
        return ability.can('view', this.parent.resourceType)
    }

    canEdit() {
        return ability.can('edit', this.parent.resourceType)
    }

    canCreate() {
        return ability.can('create', this.parent.resourceType)
    }

    canDelete() {
        return ability.can('delete', this.parent.resourceType)
    }

}

export default AbstractItemResource
