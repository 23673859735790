import AbstractListResource from "./abstract/abstract-list-resource"
import defines from "dpsdk/defines"
const {Resource, AlertType} = defines
import Alert from './items/alert'

class Alerts extends AbstractListResource {
    constructor() {
        super(Resource.ALERTS, Alert)
        super.setCallbacks({
            get: (params) => this.api().resourceGet(Resource.ALERTS, params),
            update: (id, params) => this.api().resourceUpdate(Resource.ALERTS, id, params),
            create: (params) => this.api().resourceCreate(Resource.ALERTS, params),
            delete: (id) => this.api().resourceDelete(Resource.ALERTS, id)
        })
    }

    
}

export default new Alerts()