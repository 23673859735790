import abstractReducer, { REDUX_STATUS } from "./abstract-reducer"

class ResourceCollection {
	constructor() {
		this.reducers = {}
		this.resources = {}
		this.workspaceResources = {}
		this.saveFileCache = false
		this.readFileCache = false
	}

	getApi() {
		return this.api
	}

	setApi(api) {
		this.api = api
	}

	setSaveFileCache(enabled) {
		this.saveFileCache = enabled
	}

	setReadFileCache(enabled) {
		this.readFileCache = enabled
	}

	isSaveCache() {
		return this.saveFileCache
	}

	isReadCache() {
		return this.readFileCache
	}

	add(type, instance, isWorkspaceResource = false) {
		this.resources[type] = instance
		if (isWorkspaceResource === true) {
			this.workspaceResources[type] = instance
		}

		this.reducers[type] = (state = {instance, status: REDUX_STATUS.UNKNOWN}, action) => {
			return abstractReducer(state, action, type)
		}
	}

	addReducer(type, instance) {
		this.reducers[type] = (state = {instance, status: REDUX_STATUS.UNKNOWN}, action) => {
			return abstractReducer(state, action, type)
		}
	}

	getReducers() {
		return this.reducers
	}

	getResources() {
		return this.resources
	}

	getWorkspaceResources() {
		return this.workspaceResources
	}

	getResource(type) {
		return this.resources[type]
	}
}

export default new ResourceCollection()