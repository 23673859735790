import AbstractItemResource from "../abstract/abstract-item-resource"
import defines from "dpsdk/defines"
const {Resource, DetectionProfileAllowedDirection} = defines
import Zone from './zone'
import api from '../../api'
import axios from 'axios'
import iso from "iso-3166-1"
import countries from "i18n-iso-countries"

class VehicleLog extends AbstractItemResource {
    constructor(data, parent) {
        super(Resource.VEHICLE_LOGS, data, parent)
        this.setLabels({
            id: 'ID'
        })
    }

    getThumbUrl() {
        return `${api.getApiUrl()}/${Resource.VEHICLE_LOGS.toLowerCase()}/${this.id}/files/${this.photo_filename}`
    }

    getFileUrl(filename = null) {
        if (!filename) {
            filename = this.photo_filename
        }
        return `${api.getApiUrl()}/${Resource.VEHICLE_LOGS.toLowerCase()}/${this.id}/files/${filename}`
    }

    getReportUrl() {
        return `${api.getApiUrl()}/${Resource.VEHICLE_LOGS.toLowerCase()}/${this.id}/download?photo_filename=${this.photo_filename}`
    }

    getDetails() {
        const url = `${api.getApiUrl()}/${Resource.VEHICLE_LOGS.toLowerCase()}/${this.id}/details`
        return axios.get(url)
    }

    getDirectionStr(t) {
        switch (this.direction) {
            case DetectionProfileAllowedDirection.OPPOSITE:
                return t('vehicleLogs.opposite')
            case DetectionProfileAllowedDirection.PASSING:
                return t('vehicleLogs.passing')
            default:
                return t('vehicleLogs.unknown')
        }
    }

    getViolationsStr(t) {
        if (!this.violations || this.violations === '0') {
            return `C0 - ${t('vehicleLogs.noViolations')}`
        }

        const arr = this.violations.split(',')

        return arr.map(el => {
            return `${Zone.violationStr(t, parseInt(el))}`
        }).join(', ')
    }

    sizeClassStr(t) {
        return this.size_class || t('vehicleLogs.unknown')
    }

    countryStr(t, language) {
        const isoItem = iso.whereNumeric(this.country) || iso.whereNumeric(`0${this.country}`) || iso.whereNumeric(`00${this.country}`)
        if (isoItem) {
            return countries.getName(isoItem.alpha2, language) || isoItem.country
        }

        return t('vehicleLogs.unknown')
    }

    vehicleTypeStr(t) {
        if (this.truck_type !== undefined && this.truck_type !== null) {
            const el = this.parent.typesForDropDown(t).find((el) => el.value.toString() === this.truck_type.toString())
            if (el) {
                return el.label
            }
        }

        return t('vehicleLogs.unknown')
    }

    colorStr(t) {
        return t(`vehicleLogs.color.${this.color}`)
    }
}

export default VehicleLog
