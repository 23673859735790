import AbstractItemResource from "../abstract/abstract-item-resource"
import defines from "dpsdk/defines"
const {Resource, AlertType} = defines

class Alert extends AbstractItemResource {
    constructor(data, parent) {
        super(Resource.ALERTS, data, parent)
        this.setLabels({
            id: 'ID'
        })
    }

    static typesForDropDown(t) {
        return [
            {
                label: t('alerts.type.central'),
                value: AlertType.CENTRAL
            },
            {
                label: t('alerts.type.vehicle'),
                value: AlertType.VEHICLE
            },
            {
                label: t('alerts.type.device'),
                value: AlertType.DEVICE
            },
            {
                label: t('alerts.type.door'),
                value: AlertType.DOOR
            }
        ]
    }

    getTypeStr(t) {
        let result = ''
        const el = Alert.typesForDropDown(t).find(el => el.value === this.type)
        if (el) {
            result += el.label
        }

        if (this.plate) {
            result += ` ${this.plate}`
        }

        if (this.device_id > 0) {
            result += ` (${t('alerts.deviceId')} #${this.device_id})`
        }

        return result
    }
}

export default Alert