import resourceCollection from "./abstract/resource-collection"
import AbstractListResource from "./abstract/abstract-list-resource"
import defines from "dpsdk/defines"
const {Resource} = defines
import Role from './items/role'

class Roles extends AbstractListResource {
	constructor() {
		super(Resource.ROLES, Role)
		super.setCallbacks({
			get: (params) => this.api().resourceGet(Resource.ROLES, params),
			update: (id, params) => this.api().resourceUpdate(Resource.ROLES, id, params),
			delete: (id) => this.api().resourceDelete(Resource.ROLES, id),
			create: (params) => this.api().resourceCreate(Resource.ROLES, params)
		})
	}

	forDropDown(t, types = null, empty = null) {
		const result = []
		if (!this.isInitialized()) {
			return []
		}

		if (empty) {
			result.push({
				label: empty,
				value: null
			})
		}

		this.getItems().forEach(item => {
			if (!Array.isArray(types) || types.includes(item.type)) {
				result.push({
					value: item.id,
					label: item.getTitle(t)
				})
			}
		})
		return result
	}

	onDeleted() {
		return (dispatch) => {
			dispatch(resourceCollection.getResource(Resource.USERS).refresh())
			dispatch(resourceCollection.getResource(Resource.PROFILE).refresh())
			dispatch(super.onDeleted())
		}
	}

}

export default new Roles()