import axios from 'axios'
import * as errorCode from '../resources/api/errors'
import * as status from '../resources/api/statuses'
import jwt from "jsonwebtoken"
import io from 'socket.io-client'
import { store } from '../redux/storeConfig/store'
import fileDownload from 'js-file-download'

const REQUEST_POST = 'POST'
const REQUEST_GET = 'GET'
const REQUEST_BLOB = 'BLOB'
const REQUEST_PUT = 'PUT'
const REQUEST_DELETE = 'DELETE'
const REQUEST_UPLOAD_POST = 'UPLOAD_POST'
const REQUEST_UPLOAD_PUT = 'UPLOAD_PUT'

import resourceCollection from '../resources/abstract/resource-collection'
import defines from 'dpsdk/defines'
const { RoleType } = defines
import ability, {prepareRules} from '../configs/acl/ability'
import config from '../configs/apiConfig'

export const ROUTE = {
    SIGN_IN: "/auth/signin",
    SIGN_IN_LOCAL: "/auth/signin/local",
    SEND_PASSWORD_RESET_LINK: "/auth/send-password-reset-email",
    RESET_PASSWORD: "/auth/password-reset",
    REFRESH_TOKEN: "/auth/refresh-token",
    SEND_VERIFY_EMAIL: "/auth/send-verify-email",
    CHECK_RESET_PASSWORD_TOKEN: "/auth/check-password-reset-token",
    SIGNUP: "/auth/signup",
    VERIFY_EMAIL: "/auth/verify-email",
    PROFILE: "/profile",
    CHANGE_PASSWORD: "/profile/change-password",
    AVATAR: "/profile/avatar",
    LOGOUT: "/profile/logout",
    SCREENS: "/screens",
    GROUPS: "/groups",
    ROLES: "/roles",
    MEMBERS: "/members",
    USERS: "/users",
    CHECK_PAIRING_CODE: "/screens/check-pairing-code",
    SEND_INVITATION_LINK: "/users/send-invitation-link",
    SUBSCRIPTION_INTENT: "/subscriptions/intent",
    SCREENS_REFRESH: "/screens/refresh",
    CONFIG_FRONT: "/config/front",
    PROPAGATE_SERVICES: "/operator/propagate-services",
    REMOVE_EPG: "/contents/remove-epg",
    PARSE_EPG: "/contents/parse-epg",
    LICENSE: "/operator/license",
    SUB_OPERATOR_LOGIN: "/operator/login",
    SUB_OPERATOR_LOGOUT: "/operator/logout",
    STATUS: "/statistics/status",
    YEAR_STATISTICS: "/statistics/year",
    MONTH_STATISTICS: "/statistics/month",
    DAYS_STATISTICS: "/statistics/days",
    HOURS_STATISTICS: "/statistics/hours"
}

class Api {
    constructor() {
        this.sessionId = this.makeSessionId()
        this.config = config
        axios.defaults.baseURL = this.config.apiURL
        axios.defaults.headers.common['Session-ID'] = this.sessionId
        axios.defaults.headers.common['Content-Type'] = "application/json"
        const accessToken = localStorage.getItem('accessToken')
        if (accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
            const decodedToken = jwt.decode(accessToken)
            this.initSocket(decodedToken.user.operatorId)
        }
    }

    makeSessionId(length = 32) {
        let result = ''
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        const charactersLength = characters.length
        let counter = 0
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength))
            counter += 1
        }
        return result
    }

    getCancelToken() {
        return axios.CancelToken.source()
    }

    getHost() {
        return this.config.hostURL
    }

    getApiUrl() {
        return `${this.getHost()}/api/dashboard`
    }

    refreshScreens(screenIds) {
        return this.post(ROUTE.SCREENS_REFRESH, screenIds)
    }

    handleSocketEvent(event, msg) {

        if (msg.sessionId !== this.sessionId) {
            const resource = resourceCollection.getResource(msg.resource)

            if (resource) {

                const dataList = Array.isArray(msg.data) ? msg.data : [msg.data]

                dataList.forEach(dataItem => {
                    let equal = false
                    let updateResource = resource
                    if (updateResource.getParentField()) {
                        const parent = updateResource.getParent()
                        if (parent) {
                            const itemResource = parent.getSingleItem(dataItem[updateResource.getParentField()])
                            if (itemResource && itemResource.getChildren()) {
                                if (itemResource.getChildren().isInitialized()) {
                                    updateResource = itemResource.getChildren()
                                    equal = true
                                }
                            }
                        }
                    } else {
                        if (updateResource.isInitialized()) {
                            equal = true
                        }
                    }

                    if (equal && updateResource.canView()) {
                        switch (event) {
                            case 'delete': {
                                store.dispatch(updateResource.deleted(dataItem.id, true))
                                break
                            }
                            case 'create': {
                                store.dispatch(updateResource.created(dataItem, true))
                                break
                            }
                            case 'update': {
                                store.dispatch(updateResource.updated(dataItem, true))
                                break
                            }
                        }
                    }
                })
            }
        }
    }

    initSocket(operatorId) {
        if (this.getRoleType() !== RoleType.OPERATOR &&
            this.getRoleType() !== RoleType.ADMINISTRATOR) {
            return
        }

        this.socket = io(this.config.socketURL, {
            query: { roomId: operatorId }
        })

        this.socket.on('delete', (msg) => {
            this.handleSocketEvent('delete', msg)
        })

        this.socket.on('create', (msg) => {
            this.handleSocketEvent('create', msg)
        })

        this.socket.on('update', (msg) => {
            this.handleSocketEvent('update', msg)
        })
    }

    baseUrl() {
        return this.config.apiURL
    }

    userLogout() {
        const refreshToken = localStorage.getItem('refreshToken')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('userData')
        localStorage.removeItem('accessTokenExpiresAt')
        localStorage.removeItem('workspace')

        if (this.socket) {
            this.socket.close()
        }

        return new Promise((resolve) => {
            if (axios.defaults.headers.common['Authorization']) {
                return this.post(ROUTE.LOGOUT, {
                    refreshToken
                }).finally(() => {
                    delete axios.defaults.headers.common['Authorization']
                    resolve()
                })
            }
        })
    }

    receiveToken(accessToken, refreshToken) {
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

        const decodedToken = jwt.decode(accessToken)
        if (decodedToken) {
            const expiresAt = Math.round(Date.now() / 1000) + (decodedToken.exp - decodedToken.iat)
            localStorage.setItem('userData', JSON.stringify(decodedToken.user))
            localStorage.setItem('accessTokenExpiresAt', expiresAt.toString())
            return decodedToken.user
        }

        return null
    }

    processErrors(reject, error) {
        if (error.response && error.response.data) {
            reject(error.response.data)
        } else if (axios.isCancel(error)) {
            reject({
                status: status.REQUEST_CANCELLED,
                error: errorCode.REQUEST_CANCELLED,
                validation: {}
            })
        } else {
            reject({
                status: status.SERVER_ERROR,
                error: errorCode.SERVER_ERROR,
                validation: {}
            })
        }
    }

    catchErrors(request, resolve, reject, error) {
        if (error.response && error.response.status === status.NOT_AUTHORIZED) {
            this.refreshToken().then(({accessToken, refreshToken}) => {
                this.receiveToken(accessToken, refreshToken)
                this.request(request.type, request.route, request.params, false).then((data) => {
                    resolve(data)
                }).catch((err) => {
                    this.processErrors(reject, err)
                })
            }).catch((err) => {
                if (err.status !== status.SERVER_ERROR) {
                    this.userLogout().then(() => {
                        location.href = '/'
                    })
                }

                reject({
                    status: status.NOT_AUTHORIZED,
                    error: errorCode.NOT_AUTHORIZED,
                    validation: {}
                })
            })
        } else {
            this.processErrors(reject, error)
        }
    }

    request(type, route, params, catchErrors = true) {

        return new Promise((resolve, reject) => {
            switch (type) {
                case REQUEST_GET: {
                    axios.get(route, {params}).then((res) => {
                        resolve(res.data)
                    }).catch((error) => {
                        if (catchErrors) {
                            this.catchErrors({type, route, params}, resolve, reject, error)
                        } else {
                            reject(error)
                        }
                    })
                    break
                }
                case REQUEST_BLOB: {
                    console.log('blob', params)
                    axios({
                        url: route,
                        method: 'GET',
                        responseType: 'blob'
                    }, {params}).then((res) => {
                        resolve(res.data)
                    }).catch((error) => {
                        if (catchErrors) {
                            this.catchErrors({type, route, params}, resolve, reject, error)
                        } else {
                            reject(error)
                        }
                    })
                    break
                }
                case REQUEST_POST: {
                    axios.post(route, params).then((res) => {
                        resolve(res.data)
                    }).catch((error) => {
                        if (catchErrors) {
                            this.catchErrors({type, route, params}, resolve, reject, error)
                        } else {
                            reject(error)
                        }
                    })
                    break
                }
                case REQUEST_PUT: {
                    axios.put(route, params).then((res) => {
                        resolve(res.data)
                    }).catch((error) => {
                        if (catchErrors) {
                            this.catchErrors({type, route, params}, resolve, reject, error)
                        } else {
                            reject(error)
                        }
                    })
                    break
                }
                case REQUEST_DELETE: {
                    axios.delete(route, {params}).then((res) => {
                        resolve(res.data)
                    }).catch((error) => {
                        if (catchErrors) {
                            this.catchErrors({type, route, params}, resolve, reject, error)
                        } else {
                            reject(error)
                        }
                    })
                    break
                }
                case REQUEST_UPLOAD_PUT:
                case REQUEST_UPLOAD_POST: {

                    const bodyFormData = new FormData()
                    if (params.file) {
                        bodyFormData.append('file', params.file)
                        bodyFormData.append('filename', params.file.name)
                    }
                    if (params.files) {
                        Object.keys(params.files).forEach(key => {
                            if (params.files[key]) {
                                bodyFormData.append(key, params.files[key])
                            }
                        })
                    }
                    if (params.postParams) {
                        Object.keys(params.postParams).forEach(key => {
                            if (typeof params.postParams[key] === 'object') {
                                bodyFormData.append(key, JSON.stringify(params.postParams[key]))
                            } else {
                                bodyFormData.append(key, params.postParams[key])
                            }
                        })
                    }

                    const func = type === REQUEST_UPLOAD_PUT ? axios.put : axios.post

                    func(route, bodyFormData, {
                        headers: { "Content-Type": "multipart/form-data" },
                        onUploadProgress: params.onProgress,
                        cancelToken: params.cancelToken
                    }).then((res) => {
                            resolve(res.data)
                        }).catch((error) => {
                            if (catchErrors) {
                                this.catchErrors({type, route, params}, resolve, reject, error)
                            } else {
                                reject(error)
                            }
                        })
                    break
                }
            }
        })
    }

    resourceRead(resourceType, id, params) {
        return new Promise((resolve, reject) => {
            this.get(`${resourceType.toLowerCase()}/${id}`, params).then((result) => {
                resolve(result)
            }).catch((result) => {
                reject(result)
            })
        })
    }

    resourceGet(resourceType, params) {
        return new Promise((resolve, reject) => {
            this.get(resourceType.toLowerCase(), params).then((result) => {
                resolve(result)
            }).catch((result) => {
                reject(result)
            })
        })
    }

    resourceDropDownList(resourceType, params) {
        return new Promise((resolve, reject) => {
            this.get(`${resourceType.toLowerCase()}/drop-down-list`, params).then((result) => {
                resolve(result)
            }).catch((result) => {
                reject(result)
            })
        })
    }

    resourceCreate(resourceType, params, upload) {
        return new Promise((resolve, reject) => {
            if (upload) {
                this.uploadPost(resourceType.toLowerCase(), params).then((result) => {
                    resolve(result)
                }).catch((result) => {
                    reject(result)
                })
            } else {
                this.post(resourceType.toLowerCase(), params).then((result) => {
                    resolve(result)
                }).catch((result) => {
                    reject(result)
                })
            }
        })
    }

    resourceUpload(resourceType, params) {
        return new Promise((resolve, reject) => {
            this.upload(`${resourceType.toLowerCase()}/upload`, params).then((result) => {
                resolve(result)
            }).catch((result) => {
                reject(result)
            })
        })
    }

    resourceExport(resourceType, params = {}) {
        const paramsStr = Object.keys(params).map(key => `${key}=${params[key]}`).join('&')
        return new Promise((resolve, reject) => {
            this.blob(`${resourceType.toLowerCase()}/export/xlsx${paramsStr ? `?${paramsStr}` : ''}`, {
                responseType: 'blob'
            }).then((result) => {
                fileDownload(result, 'export.xlsx')
                resolve(true)
            }).catch((result) => {
                reject(result)
            })
        })
    }

    resourceDelete(resourceType, id) {
        return new Promise((resolve, reject) => {
            let route = `${resourceType.toLowerCase()}/${id}`
            let params = {}
            if (Array.isArray(id)) {
                route = resourceType.toLowerCase()
                params = {id}
            }

            this.delete(route, params).then((result) => {
                resolve(result)
            }).catch((result) => {
                reject(result)
            })
        })
    }

    resourceUpdate(resourceType, id, params, upload = false) {
        return new Promise((resolve, reject) => {
            let route = `${resourceType.toLowerCase()}/${id}`
            if (Array.isArray(id)) {
                route = `${resourceType.toLowerCase()}`
                params = {id, params}
            }

            if (upload) {
                this.uploadPut(route, params).then((result) => {
                    resolve(result)
                }).catch((result) => {
                    reject(result)
                })
            } else {
                this.put(route, params).then((result) => {
                    resolve(result)
                }).catch((result) => {
                    reject(result)
                })
            }
        })
    }

    get(route, params) {
        return this.request(REQUEST_GET, route, params)
    }

    blob(route, params) {
        return this.request(REQUEST_BLOB, route, params)
    }

    post(route, params) {
        return this.request(REQUEST_POST, route, params)
    }

    put(route, params) {
        return this.request(REQUEST_PUT, route, params)
    }

    delete(route, params) {
        return this.request(REQUEST_DELETE, route, params)
    }

    propagateServices() {
        return this.post(ROUTE.PROPAGATE_SERVICES)
    }

    removeEpg(channelId) {
        if (channelId) {
            return this.post(`${ROUTE.REMOVE_EPG}/${channelId}`)
        } else {
            return this.post(ROUTE.REMOVE_EPG)
        }
    }

    parseEpg(channelId) {
        if (channelId) {
            return this.post(`${ROUTE.PARSE_EPG}/${channelId}`)
        } else {
            return this.post(ROUTE.PARSE_EPG)
        }
    }

    getLicense() {
        return this.get(ROUTE.LICENSE)
    }

    upload(route, params) {
        return this.request(REQUEST_UPLOAD_POST, route, params)
    }

    uploadPut(route, params) {
        return this.request(REQUEST_UPLOAD_PUT, route, params)
    }

    uploadPost(route, params) {
        return this.upload(route, params)
    }

    subscriptionIntent(params) {
        return this.post(ROUTE.SUBSCRIPTION_INTENT, params)
    }

    refreshToken() {
        if (this.refreshTokenPromise !== undefined) {
            return this.refreshTokenPromise
        }

        this.refreshTokenPromise = new Promise((resolve, reject) => {
            const refreshToken = localStorage.getItem('refreshToken')
            if (!refreshToken) {
                reject({
                    status: status.NOT_AUTHORIZED,
                    error: errorCode.NOT_AUTHORIZED,
                    validation: {}
                })
                this.refreshTokenPromise = undefined
            } else {
                this.post(ROUTE.REFRESH_TOKEN, { refreshToken }).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                }).finally(() => {
                    this.refreshTokenPromise = undefined
                })
            }
        })
        return this.refreshTokenPromise
    }

    uploadAvatar(file) {
        return this.upload(ROUTE.AVATAR, {file})
    }

    deleteAvatar() {
        return this.delete(ROUTE.AVATAR)
    }

    currentUser() {
        return this.get(ROUTE.PROFILE)
    }

    updateProfile(params) {
        return this.put(ROUTE.PROFILE, params)
    }

    isLoggedIn() {
        return !!localStorage.getItem('userData')
    }

    getRoleType() {
        const userData = localStorage.getItem('userData')
        try {
            const json = JSON.parse(userData)
            if (!json || !json.role) {
                return null
            }
            return json.role.type
        } catch (e) {
            return null
        }
    }

    isSubOperatorLoggedIn() {
        const userData = localStorage.getItem('userData')
        try {
            const json = JSON.parse(userData)
            if (!json || !json.nativeOperatorId || json.nativeOperatorId === json.operatorId) {
                return false
            }
            return json.operatorId
        } catch (e) {
            return false
        }
    }

    userLogin(credentials) {
        if (credentials.social) {
            window.location.href = `${this.config.apiURL + ROUTE.SIGN_IN}/${credentials.social}?app=${this.config.redirectUrl}`
        } else {
            return new Promise((resolve, reject) => {
                this.post(ROUTE.SIGN_IN_LOCAL, credentials).then(({accessToken, refreshToken}) => {
                    const userData = this.receiveToken(accessToken, refreshToken)
                    this.initSocket(userData.operatorId)
                    ability.update(prepareRules(userData))

                    resolve(userData)
                }).catch((errors) => {
                    reject(errors)
                })
            })
        }
    }

    subOperatorLogin(operatorId) {
        return new Promise((resolve, reject) => {
            this.post(`${ROUTE.SUB_OPERATOR_LOGIN}/${operatorId}`).then(({accessToken, refreshToken}) => {
                const userData = this.receiveToken(accessToken, refreshToken)

                this.initSocket(userData.operatorId)

                resolve(userData)
            }).catch((errors) => {
                reject(errors)
            })
        })
    }

    subOperatorLogout() {
        return new Promise((resolve, reject) => {
            this.post(ROUTE.SUB_OPERATOR_LOGOUT).then(({accessToken, refreshToken}) => {
                const userData = this.receiveToken(accessToken, refreshToken)
                this.initSocket(userData.operatorId)
                resolve(userData)
            }).catch((errors) => {
                reject(errors)
            })
        })
    }

    singInWithToken(token) {
        localStorage.setItem('refreshToken', token)
        this.refreshToken().then(({accessToken, refreshToken}) => {
            this.receiveToken(accessToken, refreshToken)
            window.location.href = '/'
        })
    }

    sendPasswordResetEmail(email) {
        return this.post(ROUTE.SEND_PASSWORD_RESET_LINK, {email})
    }

    resetPassword(token, password) {
        return this.put(ROUTE.RESET_PASSWORD, {token, password})
    }

    changePassword(currentPassword, newPassword) {
        return this.put(ROUTE.CHANGE_PASSWORD, {currentPassword, newPassword})
    }

    checkResetPasswordToken(token) {
        return this.post(ROUTE.CHECK_RESET_PASSWORD_TOKEN, {token})
    }

    getStatisticsStatus() {
        return this.get(ROUTE.STATUS)
    }

    getYearStatistics(params) {
        return this.get(ROUTE.YEAR_STATISTICS, params)
    }

    getMonthStatistics(params) {
        return this.get(ROUTE.MONTH_STATISTICS, params)
    }

    getDaysStatistics(params) {
        return this.get(ROUTE.DAYS_STATISTICS, params)
    }

    getHoursStatistics(params) {
        return this.get(ROUTE.HOURS_STATISTICS, params)
    }

    checkPairingCode(pairingCode) {
        return this.post(ROUTE.CHECK_PAIRING_CODE, {pairingCode})
    }

    registerUser(credentials) {
        return this.post(ROUTE.SIGNUP, credentials)
    }

    verifyEmail(token) {
        return this.put(ROUTE.VERIFY_EMAIL, {token})
    }

    sendVerifyEmail(email) {
        return this.post(ROUTE.SEND_VERIFY_EMAIL, {email})
    }

    sendInvitationLink(userId) {
        return this.post(ROUTE.SEND_INVITATION_LINK, {userId})
    }


}

export default new Api()
